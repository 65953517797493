.contact-container {
  background: #FFFFFF;
  border-radius: 10px;
  border: 3px solid #5bc0de;
  width: 60vw;
  margin: auto;
  padding: 20px;
  box-shadow: 6px 7px 17px 0px rgba(0,0,0,0.61);
  -webkit-box-shadow: 6px 7px 17px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 6px 7px 17px 0px rgba(0,0,0,0.61);
  color: #132b47;
}

.contact-image{
  text-align: center;
}
.contact-image img{
  background-color: #132b47;
  border-radius: 10rem;
  border: 2px solid #5bc0de;
  width: 10%;
  margin-top: -13vh;
}

.mi-img{
  width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .contact-container{
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .contact-image img {
    width: 25%;
  }
}
